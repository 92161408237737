<template>
  <div class="points-mall" ref="scrollBox"  @scroll="handleScroll($event)">
    <div class="top">
      <nav-bar
        :isFixed="true"
        :goBack="goBack"
        :title="$t('積分商城')"
        :bgc="'background-color: #f8f8f8;'"
        v-if="isMobile()"
      ></nav-bar>
      <div class="nav" v-else>
        <img
          class="img"
          @click="gotoBack"
          src="@/assets/icons/Arrow_left.png"
          alt=""
        />
        <div class="title">{{ $t("積分商城") }}</div>
      </div>
      <div class="point-box">
        <div @click="gotoPointRecord" style="min-width: 70px">
          <div class="point-label">{{ $t("可用積分") }}</div>
          <div class="point-num">{{ availablePoints }}</div>
        </div>
        <div class="btn-box">
          <div class="btn" style="margin-right: 8px" @click="gotoRecord">
            {{ $t("兌換記錄") }}
          </div>
          <div class="btn" @click="gotoRules">{{ $t("積分規則") }}</div>
        </div>
      </div>
    </div>
    <div class="tab-box">
      <div
        class="nav-box"
        sticky
        color="#71B800"
        background="#f7f9fa"
        title-active-color="#71B800"
      >
        <!-- <van-tab name="all" title-class="tab-item" :title="$t('全部')" /> -->
        <span
          v-for="tab in tabList"
          :key="tab.key"
          :name="tab.key"
          :title="tab.title"
          :class="tab.key === activeTab ? 'tab-item active' : 'tab-item'"
          @click="handleChange(tab.key)"
        >{{tab.title}}</span>
      </div>
    </div>
    <div class="content-box">
      <template v-if="goodsList.length > 0">
        <div
          class="goods-item"
          v-for="item in goodsList"
          :key="item.id"
          @click="gotoDetail(item)"
        >
          <div class="img-box">
            <img
              :src="getPointsImgUrl(item.picturePath) || defaultImage"
              @error="imgOnError($event)"
              alt=""
            />
          </div>
          <div class="name-box">
            {{ item.name }}
          </div>
          <div class="point-price">
            <div class="point">
              <div class="point-num">{{ item.points }}</div>
              <div class="point-label">{{ $t("積分") }}</div>
            </div>
            <div class="price">HK${{ item.price.toFixed(2) }}</div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="orderempty">
          <img src="@/assets/image/order/orderempty.png" alt="" />
          <div class="noOrder">{{ $t("空空如也") }}~</div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { getPointsImgUrl } from "@/utils/util.js";
import { queryGoodsList } from "@/api/pointsMall.js";
export default {
  data() {
    return {
      tabList: [
        {
          key: "all",
          title: this.$t("全部"),
        },
        {
          key: "first",
          title: "0~10000",
        },
        {
          key: "second",
          title: "10000~30000",
        },
        {
          key: "third",
          title: "30000+",
        },
      ],
      goodsList: [],
      activeTab: "all",
      loading: false,
      pageIndex: 1,
      pageSize: 10,
      availablePoints: 0,
      defaultImage: require("@/assets/image/default.png"),
      total: 0,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getPointsImgUrl,
    gotoBack() {
      this.$router.push({ path: "/" });
    },
    isMobile() {
      var u = navigator.userAgent;
      const isAndroid =
        (u.indexOf("Android") > -1 || u.indexOf("Adr") > -1) &&
        u.indexOf("MMMMessenger") > -1; //android终端
      const isIos =
        !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) &&
        u.indexOf("MMMMessenger") > -1; //ios终端
      return isAndroid || isIos;
    },
    imgOnError(e) {
      e.target.src = this.defaultImage;
    },
    async getList() {
      this.loading = true;
      const langEnv = this.$route.query.langEnv;

      const res = await queryGoodsList({
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        langEnv: langEnv?langEnv:window.localStorage.getItem("langEnv"),
      },langEnv);
      if (res.resultID === 1200) {
        this.loading = false;
        this.availablePoints = res.data.points;
        this.total = res.data.total;
        if (this.pageIndex > 1) {
          this.goodsList = [...this.goodsList, ...res.data.products];
        } else {
          this.goodsList = res.data.products;
        }
        switch (this.activeTab) {
          case "first":
            this.goodsList = this.goodsList.filter(
              (item) => item.points >= 0 && item.points < 10000
            );
            break;
          case "second":
            this.goodsList = this.goodsList.filter(
              (item) => item.points >= 10000 && item.points < 30000
            );
            break;
          case "third":
            this.goodsList = this.goodsList.filter(
              (item) => item.points >= 30000
            );
            break;
        }
      }
    },
    handleScroll(e) {
      if (
        e.target.scrollTop + e.target.clientHeight + 1 >=
          e.target.scrollHeight &&
        !this.loading
      ) {
        if (this.pageIndex * this.pageSize < this.total) {
          this.pageIndex += 1;
          this.getList();
        }
      }
    },
    async handleChange(val) {
      if (val !== this.activeTab) {
       
        this.activeTab = val;
        this.pageIndex = 1;
        await this.getList();
         const scrollBox = this.$refs.scrollBox;
        // 设置 scrollTop 为 0，滚动到顶部
        if (scrollBox) {
          scrollBox.scrollTop = 0;
        }
      }
    },
    gotoRecord() {
      this.$router.push({ path: "/pointsMall/exchangeRecord" });
    },
    gotoRules() {
      this.$router.push({
        path: "/about",
        query: {
          id: 13,
        },
      });
    },
    gotoPointRecord() {
      this.$router.push({
        path: "/pointsMall/pointsRecord",
      });
    },
    gotoDetail(item) {
      this.$router.push({
        path: `/pointsMall/detail/${item.id}`,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
:deep(.tab-item) {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #999999;
  line-height: 22px;
}
:deep(.van-tab--active) {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
}
.points-mall {
  width: 100%;
  height: 100vh;
  background: #f7f9fa;
  overflow-y: scroll;
  position: relative;
  .top {
    width: 100%;
    height: 210px;
    background-image: url("../../assets/image/points-mall-bg.png");
    background-size: 100% 100%;
    .nav {
      width: 100%;
      height: 44px;
      line-height: 44px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 18px;
      color: #1a1a1a;
      text-align: center;
      position: relative;
      .img {
        width: 24px;
        height: 24px;
        position: absolute;
        left: 14px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .point-box {
      padding: 16px 0 0 24px;
      .point-label {
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 13px;
        color: rgba(0, 0, 0, 0.9);
        line-height: 20px;
        margin-bottom: 4px;
      }
      .point-num {
        font-family: OPlusSans 3, OPlusSans 30;
        font-weight: bold;
        font-size: 24px;
        color: rgba(0, 0, 0, 0.9);
        line-height: 30px;
      }
      .btn-box {
        margin-top: 16px;
        display: flex;
        // column-gap: 8px;
        .btn {
          padding: 3px 12px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 12px;
          color: #ffffff;
          line-height: 18px;
          background: rgba(0, 0, 0, 0.15);
          border-radius: 18px 18px 18px 18px;
        }
      }
    }
  }
  .tab-box {
    width: 100%;
    padding: 12px 12px 16px;
    background: #f7f9fa;
    border-radius: 12px 12px 0 0;
    position: sticky;
    z-index: 888;
    top: 0;
  }
  .content-box {
    width: 100%;
    // height: 1000px;
    // height: calc(100vh - 295px);
    overflow: auto;
    padding: 0 12px;
    // padding-top: 295px;
    display: flex;
    flex-wrap: wrap;
    // column-gap: 9px;
    // row-gap: 12px;
    .goods-item {
      width: calc(calc(100% - 9px) / 2);
      height: 270px;
      background: #fff;
      padding: 8px 12px 14px;
      border-radius: 12px 12px 0 0;
      margin-right: 9px;
      margin-bottom: 12px;
      &:nth-child(2n) {
        margin-right: 0;
      }
      .img-box {
        width: 100%;
        height: 150px;
        img {
          width: 100%;
          height: 150px;
        }
      }
      .name-box {
        margin-top: 16px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #6c737a;
        line-height: 22px;
        height: 44px;
        word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* 控制最大行数 */
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .point-price {
        // display: flex;
        // align-items: center;
        margin-top: 8px;
        .point {
          display: flex;
          align-items: center;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #666666;
          line-height: 18px;
          .point-num {
            font-weight: bold;
            font-size: 16px;
            color: #90d12e;
            line-height: 24px;
            margin-right: 4px;
          }
        }
        .price {
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 11px;
          color: #bdbdbd;
          line-height: 16px;
          text-decoration: line-through;
        }
      }
    }
    .orderempty {
      width: 100%;
      display: flex;
      justify-content: center;
      align-content: center;
      flex-wrap: wrap;
      flex-direction: column;
      text-align: center;
      color: #1a1a1a;
      img {
        width: 140px;
        height: 140px;
        object-fit: contain;
      }
      .noOrder {
        width: 140px;
      }
    }
  }
}
.nav-box{
  display: flex;
  align-items: center;
  justify-content: space-between;
  span{
    width: fit-content;
    padding: 0 12px;
    height: 26px;
    border-bottom: 3px solid transparent;
  }
  .active{
    border-bottom: 3px solid #90d12e;
    color: #90d12e;
  }
}
</style>
